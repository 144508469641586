

import { service } from '@/utils/request'

export default {
  uploadImg: '/image/single',
  uploadFile: '/file/single',
  uploadImgMultiple(payload) {
    return service.post("/image/double", payload)
  },
  uploadFileMultiple(payload) {
    return service.post("/file/double", payload)
  },
  submitForm(payload) {
    return service.post('/customerCollect', payload)
  },
  changePwd(payload) {
    return service.post('/sysUser/updatePassword', payload)
  }
}
