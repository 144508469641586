import { service } from '@/utils/request'

export default {
  getResourcePage(payload) {
    let url = ''
    if (payload.type === 0) {
      url = '/picSource/page'
    } else if (payload.type === 1) {
      url = '/essaySource/page'
    } else if (payload.type === 2) {
      url = '/docSource/page'
    }
    return service.get(url, {
      params: payload
    })
  },
  deleteImgResource(payload) {
    return service.postForm(`/picSource/delete`, payload)
  },
  deleteArticleResource(payload) {
    return service.postForm('/essaySource/delete', payload)
  },
  deleteFileResource(payload) {
    return service.postForm('/docSource/delete', payload)
  },
  imgChangeGroup(payload) {
    return service.postForm('/picSource/switchGroup', payload)
  },
  fileChangeGroup(payload) {
    return service.postForm('/docSource/switchGroup', payload)
  },
  recoveryImgResource(payload) {
    return service.postForm('/picSource/recovery', payload)
  },
  recoveryArticleResource(payload) {
    return service.postForm('/essaySource/recovery', payload)
  },
  recoveryFileResource(payload) {
    return service.postForm('/docSource/recovery', payload)
  },
  articleSave(payload) {
    return service.post('/essaySource/save', payload)
  },
  getArticleDetail(payload) {
    return service.get('/essaySource/websiteDetail', {
      params: payload
    })
  },
  getRemarkPage(payload) {
    return service.get('/customerCollect/page', {
      params: payload
    })
  },
}
