<template>
  <a-modal :maskClosable='false' :title="isAdd?'新建分组':'编辑分组'" :visible="show" :confirmLoading='groupFormLoading' @ok="handleUpdateGroup('groupForm')" @cancel='$emit("close")'>
    <div class='group-form'>
      <a-form-model ref='groupForm' :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @submit.native.prevent :model="data" :rules='rules'>
        <a-form-model-item label="父级分组" prop='parentId' v-if='data.parentId!=="0"'>
          <a-select v-model="data.parentId" placeholder="请选择父级">
            <a-select-option v-for='item in list' :key='item.sourceGroupId' :value="item.sourceGroupId">
              {{item.groupName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分组名称" prop='groupName'>
          <a-input v-model="data.groupName" placeholder="分组名称">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="排序号" prop='sort'>
          <a-input v-model="data.sort" placeholder="排序号（1-1000，由小到大排序）">
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { system } from '@/api'
const checkInteger = (rule, value, callback) => {
  if (value) {
    const reg = /^[0-9]*[1-9][0-9]*$/
    if (reg.test(value) && value > 0 && value < 1001) {
      callback()
    } else {
      return callback(new Error('请输入1-1000的正整数'))
    }
  } else {
    callback()
  }
}
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    type: Number,
    list: {
      type: Array,
      default() {
        return []
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    isAdd: Boolean,
  },
  watch: {
    async show() {
      if (!this.show) {
        this.$refs.groupForm.clearValidate()
      }
    },
  },
  data() {
    return {
      groupFormLoading: false,
      rules: {
        parentId: [{ required: true, message: '请选择', trigger: 'change' }],
        groupName: [
          { required: true, message: '请输入分组名', trigger: 'blur' },
          { max: 15, message: '最多15位', trigger: 'blur' },
        ],
        sort: [{ validator: checkInteger, trigger: 'change' }],
      },
    }
  },
  methods: {
    async handleUpdateGroup(ref) {
      this.$refs[`${ref}`].validate(async (valid) => {
        if (valid) {
          this.groupFormLoading = true
          const { msg, code } = await system.updateGroup({
            ...this.data,
            type: this.type,
          })
          this.groupFormLoading = false
          if (code === '00000') {
            this.$message.success('保存成功')
            this.$emit('success', this.isAdd ? '' : this.data)
          } else {
            this.$message.error(msg || '添加失败')
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.group-form {
  ::v-deep .ant-form-item {
    margin-bottom: 4px;
  }
}
</style>