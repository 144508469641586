<template>
  <div class='group-list'>
    <div class='tree-box'>
      <a-tree :treeData='list' :replaceFields="{children:'children', title:'groupName', key:'sourceGroupId' }" :selectedKeys='selectedKeys' @select="onSelect">
      </a-tree>
      <div>
        <a-button type='link' icon='plus' @click='addGroup($event, "0")'>添加一级分组</a-button>
      </div>
    </div>
    <div class='flex-j-b flex-a-c btn-box' style='border-top:1px solid #ddd;'>
      <div style='line-height:48px;flex:1;text-align:center;background:#333;cursor: pointer;' @click='addGroup' v-if='currentGroup.parentId ==="0"'>
        <a-icon type="plus" />
      </div>
      <div style='line-height:48px;flex:1;text-align:center;background:#333;cursor: pointer;;border-left:1px solid #ddd;border-right:1px solid #ddd;' v-if='selectedKeys.length' @click='editGroup'>
        <a-icon type="edit" />
      </div>
      <div style='line-height:48px;flex:1;text-align:center;background:#333;cursor: pointer;' v-if='selectedKeys.length' @click='deleteGroup'>
        <a-icon type="delete" />
      </div>
    </div>
    <GroupForm :isAdd='isAdd' :show='popover' :list='list' :data='groupForm' @close='popover=false' :type='type' @success='saveGroupSuccess'></GroupForm>
  </div>
</template>

<script>
import { system } from '@/api'
export default {
  name: 'groupList',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    selectTreeKeys: {
      type: Array,
      default() {
        return []
      },
    },
    type: Number,
  },
  data() {
    return {
      selectedKeys: [],
      popover: false,
      groupForm: {},
      showGroupManagement: false,
      typeStr: ['图片', '文章', '文件'],
      isAdd: false,
      currentGroup: {},
    }
  },
  watch: {
    selectTreeKeys: {
      handler() {
        this.selectedKeys = this.selectTreeKeys
        this.getCurrentGroupData()
      },
      deep: true,
    },
  },
  methods: {
    onSelect(e) {
      if (e.length === 0) {
        e = this.selectedKeys
        this.selectedKeys = e
        this.getCurrentGroupData()
      } else {
        this.selectedKeys = e
        this.getCurrentGroupData()
        this.$emit('select', {
          id: e[0]
        })
      }
    },
    saveGroupSuccess(e) {
      if (e) {
        this.currentGroup = e
      }
      this.popover = false
      this.$emit('update:group')
    },
    addGroup(e, parentId) {
      if (parentId) {
        this.groupForm = {
          parentId,
        }
      } else {
        this.groupForm = {}
      }

      this.isAdd = true
      this.popover = true
    },
    editGroup() {
      this.isAdd = false
      this.groupForm = Object.assign({}, this.currentGroup)
      this.popover = true
    },
    deleteGroup() {
      this.$confirm({
        title: `确认要删除【${this.currentGroup.groupName}】分组吗？`,
        content: `删除后该分组下的分组及包含的${this.typeStr[this.type]}将移至回收站中。`,
        onOk: async () => {
          const { msg, code } = await system.deleteGroup(this.selectedKeys[0])
          if (code === '00000') {
            this.$message.success('删除成功')
            this.showGroupManagement = false
            this.$emit('update:group', true)
          } else {
            this.$message.error(msg || '删除失败')
          }
        },
      })
    },
    getCurrentGroupData() {
      this.list.forEach((item) => {
        if (item.sourceGroupId === this.selectedKeys[0]) {
          this.currentGroup = item
          return
        } else {
          if (item.children && item.children.length) {
            item.children.forEach((val) => {
              if (val.sourceGroupId === this.selectedKeys[0]) {
                this.currentGroup = val
                return
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.group-list {
  width: 240px;
  margin-right: 16px;
  background: #fff;
  box-shadow: 0 4px 8px #ddd;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}
::v-deep .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: @main-color;
  color: #fff;
}
.btn-box {
  color: #fff;
}
.tree-box {
  height: calc(100% - 48px);
  overflow: auto;
  padding: 16px;
  box-sizing: border-box;
}
</style>