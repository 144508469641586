import { service } from '@/utils/request'

export default {
  getDesign(payload) {
    return service.get('/design', {
      params: payload
    })
  },
  // 文章ids 换取列表数据
  getArticleListByIds(payload){
    return service.get('/essaySource/list', {
      params: payload
    })
  }
}
