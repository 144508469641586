<template>
  <div class='page-loading'>
    <icon-svg class="icon " icon-class="page-loading" />
  </div>
</template>

<script>
export default {
  name:'pageLoading'
}
</script>

<style lang="less" scoped>
.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: rgba(255,255,255,.5);
  .icon {
    font-size: 200px;
  }
}
</style>