
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
Vue.config.productionTip = false
import 'ant-design-vue/dist/antd.less'
import Antd from 'ant-design-vue';
import './icons'
import './components'
import '@/style/index.less'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import busPlugin from './plugins/bus'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.use(Viewer)
Viewer.setDefaults({
  navbar: false,
  title: false,
  button: false
});
Vue.use(Antd);
Vue.use(busPlugin)
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))

amapKeys.forEach(key => {
  // console.log(key)
  localStorage.removeItem(key)
})
VueAMap.initAMapApiLoader({
  // 高德的key
  key: '4ea539f3c50ae060d3913da7d2811d4d',
  // 插件集合
  plugin: ['AMap.ToolBar', 'AMap.InfoWindow', 'AMap.Geolocation'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4'
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
