import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/pages/Index.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/pages/NewsList.vue'),
    meta: { title: '新闻资讯' }
  },
  {
    path: '/news/detail/:id',
    name: 'NewsDetail',
    component: () => import('../views/pages/NewsDetail.vue'),
    meta: { title: '新闻资讯详情' ,noCache: true}
  },
  {
    path: '/sub_company_detail/:type?',
    name: 'SubCompanyDetail',
    component: () => import('../views/pages/SubCompanyDetail.vue'),
    meta: { title: '下属公司' ,noCache: true}
  },
  {
    path: '/business_sector/:type?',
    name: 'BusinessSector',
    component: () => import('../views/pages/BusinessSector.vue'),
    meta: { title: '业务板块' ,noCache: true}
  },
  {
    path: '/business_sector_all/:tab/:type',
    name: 'BusinessSectorAll',
    component: () => import('../views/pages/BusinessSectorAll.vue'),
    meta: { title: '业务板块',noCache: true }
  },
  {
    path: '/industry/:type?',
    name: 'NeIndustryws',
    component: () => import('../views/pages/Industry.vue'),
    meta: { title: '行业领域' }
  },
  {
    path: '/industry_all/:tab/:index',
    name: 'IndustryAll',
    component: () => import('../views/pages/IndustryAll.vue'),
    meta: { title: '行业领域',noCache: true }
  },
  {
    path: '/company_intro',
    name: 'CompanyIntro',
    component: () => import('../views/pages/CompanyIntro.vue'),
    meta: { title: '集团介绍' }
  },
  {
    path: '/company_culture',
    name: 'CompanyCulture',
    component: () => import('../views/pages/CompanyCulture.vue'),
    meta: { title: '集团文化' }
  },
  {
    path: '/company_honor',
    name: 'CompanyHonor',
    component: () => import('../views/pages/CompanyHonor.vue'),
    meta: { title: '公司荣誉' }
  },
  {
    path: '/company_qualification',
    name: 'CompanyQualification',
    component: () => import('../views/pages/CompanyQualification.vue'),
    meta: { title: '资质证书' }
  },
  {
    path: '/about_us',
    name: 'aboutUs',
    component: () => import('../views/pages/AboutUs.vue'),
    meta: { title: '联系我们' }
  },
  {
    path: '/project_case/:type?',
    name: 'ProjectCase',
    component: () => import('../views/pages/ProjectCase.vue'),
    meta: { title: '工程案例' }
  },
  {
    path: '/project_case_all/:tab/:type',
    name: 'ProjectCaseAll',
    component: () => import('../views/pages/ProjectCaseAll.vue'),
    meta: { title: '工程案例',noCache: true }
  },
  {
    path: '/sub_company_case_all/:tab/:type',
    name: 'subCompanyCaseAll',
    component: () => import('../views/pages/subCompanyCaseAll.vue'),
    meta: { title: '工程案例',noCache: true }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* 记录页面滚动位置 start */
let currPageRoute

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    next(vm => {
      currPageRoute = to
      vm.$nextTick(() => {
        document.documentElement.scrollTop = to.meta.scrollPosY || 0
        document.body.scrollTop = to.meta.scrollPosY || 0
      })
    })
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
  if (currPageRoute) currPageRoute.meta.scrollPosY = document.documentElement.scrollTop || document.body.scrollTop
})
/* 记录页面滚动位置 end */
export default router
