/*
 * @Author: your name
 * @Date: 2020-09-27 16:01:42
 * @LastEditTime: 2020-10-15 13:09:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yexingteam_cms/src/components/index.js
 */
import Vue from 'vue'

const reqContext = require.context('./gloable', false, /\.(vue|js)$/)
reqContext.keys().forEach(path => {
  let component = reqContext(path)
  component = component.default || component
  const componentName = component.name || (
    path
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  Vue.component(componentName, component)
})
