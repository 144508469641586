<template>
  <div>
    <div class='tools-box'>
      <div class='switch-btn-box'>
        <div class='switch-btn' :class='{hideBtn: !show}' @click="show = !show">{{show?'隐藏':'显示'}}</div>
      </div>
      <div class='tool-list' :class='{hide: !show}'>
        <div class='tool-item menu flex-a-c' @click='$emit("change-edit-state")'>
          <icon-svg v-if='editStatus' class="icon " icon-class="eye-open-icon" />
          <icon-svg v-else class="icon " icon-class="edit-icon" />
          <div class='text'>{{editStatus?'预览':'编辑'}}页面</div>
        </div>

        <div class='tool-item menu flex-a-c' @click='$emit("save-design")'>
          <icon-svg class="icon " icon-class="save-icon" />
          <div class='text'>保存数据</div>
        </div>
        <div class='tool-item menu flex-a-c' @click='$emit("publish-design")'>
          <icon-svg class="icon " icon-class="publish-icon" />
          <div class='text'>发布上线</div>
        </div>
        <div class='tool-item menu flex-a-c' @click='goResourcePage'>
          <icon-svg class="icon " icon-class="folder-icon" />
          <div class='text'>素材管理</div>
        </div>
        <div class='tool-item menu flex-a-c' @click='showChangePwdModal = true'>
          <icon-svg class="icon " icon-class="change-pwd" />
          <div class='text'>修改密码</div>
        </div>
        <div class='tool-item menu flex-a-c' @click='$router.push("/remark_page")'>
          <icon-svg class="icon " icon-class="comment-icon" />
          <div class='text'>留言板</div>
        </div>

      </div>
    </div>
    <a-modal title='修改密码' v-model="showChangePwdModal" :confirmLoading='changePwdModalConfirmLoading' @ok="submitForm('ruleForm')" @cancel="resetForm('ruleForm')">
      <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
        <a-form-model-item has-feedback label="原密码" prop="oldpass">
          <a-input v-model="ruleForm.oldpass" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="新密码" prop="pass">
          <a-input v-model="ruleForm.pass" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="确认密码" prop="checkPass">
          <a-input v-model="ruleForm.checkPass" type="password" autocomplete="off" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { common } from '@/api'
export default {
  name: 'ToolBar',
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!/^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{8,20}$/.test(value)) {
        callback(new Error('密码为8-20位数字或字母，且至少包含一个数字一个字母'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次密码输入不一致!'))
      } else {
        callback()
      }
    }
    return {
      show: true,
      ruleForm: {
        oldpass: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        oldpass: [{ required: true, message: '请输入原密码', trigger: 'change' }],
        pass: [{ required: true, validator: validatePass, trigger: 'change' }],
        checkPass: [{ required: true, validator: validatePass2, trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      showChangePwdModal: false,
      changePwdModalConfirmLoading: false,
    }
  },
  props: {
    editStatus: Boolean,
  },
  methods: {
    goResourcePage() {
      window.open(location.origin + '/resource_management/0')
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.changePwdModalConfirmLoading = true
          const { code, msg } = await common.changePwd({
            oldPassword: this.ruleForm.oldpass,
            passWord: this.ruleForm.pass,
          })
          this.changePwdModalConfirmLoading = false
          if (code === '00000') {
            this.$message.success('修改成功')
            this.showChangePwdModal = false
          } else {
            this.$message.error(msg || '修改失败，请重试')
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style lang="less" scoped>
.tools-box {
  position: fixed;
  left: 0;
  top: 130px;
  z-index: 999;
  color: @main-color;
  .tool-list {
    width: 88px;
    padding: 0 16px;
    height: 288px;
  }
  .hide {
    display: none;
  }
  .tool-item {
    height: 56px;
    width: 56px;
    border-radius: 28px;
    background: #fff;
    margin-bottom: 16px;
    padding: 0 16px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: 0 4px 8px #999;
    .icon {
      font-size: 24px;
      flex-shrink: 0;
    }
    .text {
      margin-left: 12px;
      font-size: 16px;
      display: none;
      width: 2em;
      white-space: nowrap;
    }
  }
  .menu:hover {
    background: @main-color;
    color: #fff;
    width: 140px;
  }
  .menu:hover > .text {
    display: block;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.vc-sketch {
  box-shadow: none;
}
.switch-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4em;
}
.switch-btn {
  text-align: center;
  background: @main-color;
  color: #fff;
  border-radius: 4px;
  width: 56px;
  line-height: 24px;
  cursor: pointer;
}
.hideBtn {
  margin-left: 0;
  width: 22px;
  margin-top: -24px;
  line-height: 18px;
  padding: 4px 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>