import { Modal } from 'ant-design-vue'
import axios from 'axios'
import { TOKEN } from './constants'
import { getSession, removeSession, setSession } from './session'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API
})
service.postForm = function (url, data) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    const value = data[key]
    if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, data[key])
    }
  })
  return service.post(url, formData)
}

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getSession(TOKEN)
    if (token) config.headers['Authorization'] = token
    if (document.getElementsByClassName('modal-login-timeout').length) {
      config.cancelToken = new axios.CancelToken(cancel => {
        cancel()
      })
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    const token = response.headers['authorization']
    if (token) {
      setSession(TOKEN, token)
    }
    if (res.code !== '00000') {
      // 登录验证
      if (res.code === '99999' || res.code === '40103') {
        Modal.confirm({
          title: '登录超时，请重新登录',
          okText: '重新登录',
          keyboard: false,
          onOk () {
            removeSession(TOKEN)
            location.reload()
          }
        })
        return {
          code: '00000',
          data: {}
        }
      } else {
        console.error(`You see this message because the code of the response is not '00000'(actually ${res.code}), please read the msg and check:\n${res.msg}`)
        return response.data
      }
    } else {
      return response.data
    }
  },
  error => {
    console.log('request error：', error)
    if (axios.isCancel(error)) {
      console.log('request cancel')
    } else {
      let { response } = error
      return response ? response.data : Promise.reject(error)
    }
  }
)

export { service }
