<template>
  <a-spin :spinning='pageLoading'>
    <div class="rich-text" :style='{height: height}' ref="richTextRef"></div>
  </a-spin>
</template>
<script>
import E from 'wangeditor'
import { common } from '@/api'
export default {
  name: 'Editor',
  data() {
    return {
      editor: null,
      editorContent: '',
      pageLoading: false
    }
  },
  props: {
    initData: String,
    height: {
      type: String,
      default: '600px'
    }
  },
  watch: {
    initData: {
      async handler(newVal) {
        this.editorContent = newVal
        await this.$nextTick()
        if (this.editor) this.editor.txt.html(newVal)
      },
      immediate: true,
    },
  },
  mounted() {
    this.editor = new E(this.$refs.richTextRef)
    this.editor.customConfig = {
      onchange: (html) => {
        this.editorContent = html
      },
      menus: [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'foreColor',
        'backColor',
        'link',
        'list',
        'justify',
        'image',
        'table',
        'undo',
        'redo',
      ],
      showLinkImg: false,
      uploadImgMaxSize: 10 * 1024 * 1024,
      uploadImgMaxLength: 9,
      customUploadImg: async (files, insert) => {
        let formData = new FormData()
        files.forEach((x) => {
          formData.append('file', x)
        })
        this.pageLoading = true
        const { code, msg, data } = await common.uploadImgMultiple(formData)
        this.pageLoading = false
        if (code === '00000' && data.length > 0) {
          data.forEach((x) => {
            insert(`${process.env.VUE_APP_IMG}${x}`)
          })
        } else {
          this.$message.error(msg || '图片上传失败')
        }
      },
    }
    this.editor.create()
  },
}
</script>
<style lang="less" scoped>
.rich-text {
  margin-bottom: 32px;
}
::v-deep .w-e-menu {
  z-index: 100 !important;
}
::v-deep .w-e-text-container {
  z-index: 99 !important;
  height: 100%!important;
}
</style>